import React from "react";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

import { IBundle } from "../../dto/bundle";
import styles from "./styles.module.scss";
import Input from "../common/input";
import I18n, { getLanguageEntryText } from "../../utils/i18n";

interface IPRoductInfoProps {
	quantity?: number;
	bundle: IBundle;
	onQuantityChanged: (quantity?: number) => void;
}

const ProductInfo = (props: IPRoductInfoProps) => {
	const { bundle, quantity, onQuantityChanged } = props;
	const isPhone = useMediaQuery({ query: "(max-width: 56.25em)", screen: true });

	const renderQuantitySelect = () => {
		return (
			<>
				<h2 className="mb-mini">{I18n.t("productInfo.quantity")}</h2>
				<div className="mb-mini">
					<Input
						type="number"
						min={1}
						pattern="\d*"
						style={{ width: 100 }}
						value={quantity}
						onChange={(e) => {
							const newValue = e.currentTarget.value.replace(/\D/, "");

							if (isNaN(parseInt(newValue))) {
								onQuantityChanged(undefined);
							} else {
								onQuantityChanged(parseInt(newValue));
							}
						}}
					/>
				</div>
				<div className={classNames(styles.priceContainer, "mb-small")}>
					<div>
						<strong>{getLanguageEntryText(bundle.formOfAddress) || I18n.t("productInfo.defaultFormOfAddress")}</strong> EUR{" "}
						{I18n.toNumber(bundle.netPrice)} {I18n.t("general.withoutVAT")}
					</div>
					{bundle.quantityDiscounts.map((discountEntry) => (
						<div key={discountEntry.quantity}>
							<strong>
								{getLanguageEntryText(discountEntry.formOfAddress) ||
									I18n.t("productInfo.defaultFormOfAddressPlural", { quantity: discountEntry.quantity })}
							</strong>{" "}
							EUR {I18n.toNumber(discountEntry.netPrice)} {I18n.t("general.withoutVAT")}
						</div>
					))}
				</div>
			</>
		);
	};

	return (
		<div>
			{isPhone ? (
				<>
					<div className={styles.productInfoContainer}>
						<h1 className="mb-small">{getLanguageEntryText(bundle.name)}</h1>

						<div className={styles.imageContainer}>
							<img src={bundle.image.src} alt={getLanguageEntryText(bundle.name)} />
						</div>
						<p className={styles.productDescription}>{getLanguageEntryText(bundle.description)}</p>
					</div>
					{renderQuantitySelect()}
				</>
			) : (
				<>
					<h1 className="mb-small">{getLanguageEntryText(bundle.name)}</h1>
					{renderQuantitySelect()}

					<div className={styles.imageContainer}>
						<img src={bundle.image.src} className={classNames("mb-mini")} alt={getLanguageEntryText(bundle.name)} />
					</div>
					<div className={styles.productDescription}>{getLanguageEntryText(bundle.description)}</div>
				</>
			)}
		</div>
	);
};

export default ProductInfo;
