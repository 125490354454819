import React from "react";
import ReactSelect, { OptionsType, Styles, OptionProps, SingleValueProps } from "react-select";

interface ISelectProps {
	name?: string;
	value?: any;
	placeholder?: string;
	errorMessage?: string;
	options: OptionsType<any>;
	optionComponent?: React.ComponentClass<OptionProps<any>, any> | React.FunctionComponent<OptionProps<any>>;
	singleValueComponent?: React.ComponentClass<SingleValueProps<any>, any> | React.FunctionComponent<SingleValueProps<any>>;
	onChange: (value: any) => void;
}

const Select = (props: ISelectProps) => {
	const customSelectStyles: Partial<Styles> = {
		control: (base, state) => {
			return {
				...base,
				border: `1px solid ${props.errorMessage ? "#ea5460" : "#f0f0f0"}`,
				paddingLeft: "0.8rem",
				"&:hover": {
					border: `1px solid ${props.errorMessage ? "#ea5460" : "#f0f0f0"}`
				},
				"&:focus": {
					border: `1px solid ${props.errorMessage ? "#ea5460" : "#80c9f4"}`
				}
			};
		},
		placeholder: (base) => ({
			...base,
			color: "#adadad"
		})
	};

	return (
		<div>
			<ReactSelect
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				placeholder={props.placeholder}
				theme={(theme) => ({
					...theme,
					borderRadius: 8,
					colors: {
						...theme.colors,
						primary: "#80c9f4"
					}
				})}
				styles={customSelectStyles}
				options={props.options}
				components={{
					Option: props.optionComponent,
					SingleValue: props.singleValueComponent
				}}
			></ReactSelect>
			<div className="error" style={{ marginTop: "5px", marginLeft: "5px" }}>
				{props.errorMessage}
			</div>
		</div>
	);
};

export default Select;
