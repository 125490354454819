import React, { useCallback, useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import classNames from "classnames";

import { ReactComponent as SuccessLogo } from "../../assets/icons/success.svg";
import axios from "../../axios";
import { IBundle } from "../../dto/bundle";
import { useQuery } from "../../utils/use-query";
import I18n, { getLanguageEntryText } from "../../utils/i18n";
import styles from "./styles.module.scss";
import { getDiscountedNetPrice } from "../../utils/utils";
import Button from "../common/button";
import { trackPaymentSuccessful } from "../../utils/mixpanel";

const Success = () => {
	const query = useQuery();

	const paymentIntentId = query.get("payment_intent");
	const callbackUrl = query.get("callbackUrl");
	const mail = query.get("mail");
	const bundleId = query.get("bundleId");
	const includesVat = Boolean(query.get("includesVat") || "");
	const quantity = query.get("quantity");

	const [bundle, setBundle] = useState<IBundle | undefined>(undefined);
	const [bundleLoading, setBundleLoading] = useState(true);
	const [codeLoading, setCodeLoading] = useState(true);
	const [codeLoadRetries, setCodeLoadRetries] = useState(0);
	const [code, setCode] = useState<string | null>(null);

	useEffect(() => {
		const loadData = async (bundleId: string) => {
			const result = await axios.get<IBundle>(`/bundle/sales/${bundleId}`);

			setBundle(result.data);
			setBundleLoading(false);

			trackPaymentSuccessful(result.data.id, quantity ? Number.parseInt(quantity) : null);
		};

		if (bundleId && typeof bundleId === "string") {
			loadData(bundleId);
		} else {
			setBundleLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bundleId]);

	const loadCode = useCallback(async () => {
		if (paymentIntentId == null || code != null) {
			setCodeLoading(false);
			return;
		}

		if (codeLoadRetries === 10) {
			setCode("");
			setCodeLoading(false);
			return;
		}

		try {
			const result = await axios.get<string | null>(`/stripe/code/${paymentIntentId}`);
			setCode(result.data);

			if (result.data != null) {
				setCodeLoading(false);
			} else {
				setCodeLoadRetries(codeLoadRetries + 1);
			}
		} catch (e) {
			setCodeLoadRetries(codeLoadRetries + 1);
		}
	}, [code, codeLoadRetries, paymentIntentId]);

	useEffect(() => {
		loadCode();
	}, [loadCode]);

	if (bundleLoading || !bundle) {
		return (
			<div className={styles.loadingContainer}>
				<PuffLoader color="white" />
			</div>
		);
	}

	return (
		<>
			<div className={classNames("box", styles.container)}>
				<div className={styles.headerContainer}>
					<div className={classNames(styles.titleContainer, "mb-small")}>
						<SuccessLogo width={30} /> <h3>{I18n.t("successPage.paymentSuccessful")}</h3>
					</div>
					<p dangerouslySetInnerHTML={{ __html: I18n.t("successPage.text", { mail }) }}></p>
					<div className={styles.codeContainer}>
						<h3 className={styles.codeHeader}>{I18n.t("successPage.yourUnlockCode")}</h3>
						<div className={styles.code}>{codeLoading && code == null ? <PuffLoader color="#80c9f4" size={30} /> : code}</div>
					</div>
				</div>
				<div className={styles.productContainer}>
					<img src={bundle.image.src} className={styles.productImage} alt={getLanguageEntryText(bundle.name)}></img>
					<div className={styles.productDetails}>
						<h3 className="mb-micro">{getLanguageEntryText(bundle.name)}</h3>
						<p className="mb-micro">
							{I18n.t("productInfo.quantity")} {quantity}
						</p>
						{quantity && (
							<p>
								EUR {I18n.toNumber(getDiscountedNetPrice(bundle, parseInt(quantity)) * (includesVat ? 1.2 : 1))}{" "}
								{includesVat === true && I18n.t("general.withVAT")}
							</p>
						)}
					</div>
				</div>
				<div className={styles.supportContainer}>
					<h3 className="mb-micro">Support</h3>
					<p className="mb-micro">support@gethair.me</p>
					<p>0043 699 110 868 87</p>
				</div>
			</div>
			{callbackUrl && (
				<div className={classNames(styles.backToProductButton, "mt-medium")}>
					<Button
						onClick={() => {
							window.location.href = callbackUrl;
						}}
					>
						{I18n.t("successPage.backToProduct")}
					</Button>
				</div>
			)}
		</>
	);
};

export default Success;
