import mixpanel from "mixpanel-browser";

import env from "../env";

mixpanel.init(env.MIXPANEL_KEY, {
	api_host: "https://api-eu.mixpanel.com"
});

export const trackPageLoad = (bundleId: string | null, language: string | null) => {
	mixpanel.track("Page Load", {
		bundleId,
		language
	});
};

export const trackPaymentStarted = (bundleId: string, quantity: number) => {
	mixpanel.track("Payment started", {
		bundleId,
		quantity
	});
};

export const trackPaymentSuccessful = (bundleId: string, quantity: number | null) => {
	mixpanel.track("Payment successful", {
		bundleId,
		quantity
	});
};

export const trackPaymentFailed = (bundleId: string, paymentMethod: string, errorCode?: string) => {
	mixpanel.track("Payment failed", {
		bundleId,
		errorCode,
		paymentMethod
	});
};

export const trackStep = (stepNumber: number) => {
	mixpanel.track("Navigated to step", {
		step: stepNumber + 1
	});
};

export default mixpanel;
