import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { CustomFontSource, loadStripe } from "@stripe/stripe-js";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import styles from "./styles.module.scss";
import environment from "../env";
import Checkout from "../pages/checkout";
import Complete from "../pages/complete";

const stripePromise = loadStripe(environment.STRIPE_KEY);

const stripeFonts: CustomFontSource[] = [];

const App = () => {
	return (
		<Elements stripe={stripePromise} options={{ fonts: stripeFonts }}>
			<div className={styles.appContainer}>
				<BrowserRouter>
					<Switch>
						<Route path="/complete">
							<Complete />
						</Route>

						<Route path="/">
							<Checkout />
						</Route>
					</Switch>
				</BrowserRouter>
			</div>
		</Elements>
	);
};

export default App;
