const pt = {
	number: { format: { precision: 2, seperator: ".", delimiter: ",", strip_insignificant_zeros: false } },
	general: {
		order: "Ordem",
		payment: "Pagamento",
		mail: "O email",
		mailAddress: "Endereço de email",
		total: "Total",
		withVAT: "incl 20% de IVA",
		withoutVAT: "sem IVA",
		continue: "Continuar",
		pay: "Pagar",
		error: "Um erro ocorreu. Por favor, tente novamente mais tarde.",
		required: "Requerido",
		invoiceData: "Dados da fatura",
		apply: "Aplicar",
		cancel: "Cancelar",
		paymentMethod: "Forma de pagamento",
		paymentMethods: {
			creditCard: "Cartão de crédito",
			sofort: "Sofortüberweisung"
		}
	},
	productInfo: {
		quantity: "Quantidade:",
		defaultFormOfAddress: "1 usuário",
		defaultFormOfAddressPlural: "De %{quantidade} usuários"
	},
	mailPage: {
		subscribeText:
			"GETHAIR pode me informar sobre novidades e atualizações. O cancelamento da assinatura está disponível a qualquer momento.",
		tosLabel: "Termos e Condições",
		tosText: " lido e aceito",
		validation: {
			mailRequired: "É necessário um endereço de e-mail",
			mailInvalid: "Não é um endereço de e-mail válido",
			tosRequired: "Os Termos e Condições precisam ser aceitos"
		}
	},
	paymentPage: {
		cardHolder: "Portador do cartão",
		accountHolder: "Dono da conta",
		validation: {
			paymentMethodRequired: "Selecione um método de pagamento",
			cardHolderRequired: "O titular do cartão é obrigatório",
			cardNumberInvalid: "O número do cartão é inválido",
			invalid: "Inválido"
		},
		errors: {
			cvcInvalid: "O CVC que você inseriu é inválido.",
			cardDeclined: "Seu cartão foi recusado.",
			cardExpired: "Seu cartão expirou.",
			uidInvalid: "O ID fiscal que você inseriu é inválido."
		}
	},
	invoiceDataPage: {
		invoiceData: "Dados de fatura",
		name: "Seu nome ou nome da empresa",
		street: "rua",
		streetNumber: "Não.",
		postalCode: "Código postal",
		city: "Cidade",
		country: "País",
		uid: "CPF",
		validation: { uidInvalid: "ID fiscal inválido" }
	},
	successPage: {
		paymentSuccessful: "Pagamento bem sucedido",
		text: "Enviamos todos os detalhes do seu pedido para <strong>%{mail}</strong>.",
		backToProduct: "Voltar ao produto",
		yourUnlockCode: "Seu código de desbloqueio"
	},
	errorPage: {
		paymentFailed: "Pagamento falhou",
		text1: "Ocorreu um erro com o seu pagamento, tente novamente mais tarde.",
		text2: "Se o erro persistir, ficaremos felizes em ajudá-lo!",
		text3: "Se você entrar em contato conosco, envie-nos esta mensagem de erro para que possamos descobrir melhor o que deu errado:",
		restartPayment: "Reinicie o pagamento"
	}
};

export default pt;
