import React from "react";
import { Field } from "react-final-form";
import classNames from "classnames";
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";

import { FormInput, FormStripeCardNumberInput, FormStripeCVCInput, FormStripeExpiryInput } from "../../common/input";
import I18n from "../../../utils/i18n";
import styles from "./styles.module.scss";

const ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
	style: {
		base: {
			fontSize: "16px",
			fontFamily: "Nunito, sans-serif",
			color: "#606060",
			fontWeight: "300",
			"::placeholder": {
				color: "#adadad"
			}
		}
	}
};

const CreditCardForm = () => {
	return (
		<>
			<div className="mb-small">
				<Field
					name="cardHolder"
					component={FormInput}
					style={{ width: "100%" }}
					placeholder={I18n.t("paymentPage.cardHolder")}
				></Field>
			</div>
			<div className="mb-small">
				<Field name="cardValid" component={FormStripeCardNumberInput} options={ELEMENT_OPTIONS} />
			</div>
			<div className={classNames("mb-small", styles.cardRow)}>
				<div style={{ flex: 1, marginRight: "0.5rem" }}>
					<Field name="cardExpiryValid" component={FormStripeExpiryInput} options={ELEMENT_OPTIONS} />
				</div>
				<div style={{ flex: 1, marginLeft: "0.5rem" }}>
					<Field name="cardCVCValid" component={FormStripeCVCInput} options={ELEMENT_OPTIONS} />
				</div>
			</div>
		</>
	);
};

export default CreditCardForm;
