import React from "react";
import { Field, useFormState, useForm } from "react-final-form";
import classNames from "classnames";
import { components } from "react-select";
import { orderBy } from "lodash";
import countries from "world-countries";
import { OnChange } from "react-final-form-listeners";

import styles from "./styles.module.scss";
import I18n from "../../../../utils/i18n";
import { FormInput } from "../../../common/input";
import Select from "../../../common/select";
import { IFormData } from "../../../../dto/form";

interface IInvoiceDataProps {
	stripeCountries: string[];
}

const InvoiceData = (props: IInvoiceDataProps) => {
	const { stripeCountries } = props;
	const { values } = useFormState<IFormData>();
	const form = useForm<IFormData>();

	const SingleValue = (props: any) => {
		return (
			<components.SingleValue {...props}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<img src={`https://flagcdn.com/w20/${props.data.value.toLowerCase()}.png`} alt={props.data.label} />
					<span style={{ marginLeft: "1rem" }}>{props.data.label}</span>
				</div>
			</components.SingleValue>
		);
	};

	const SingleOption = (props: any) => (
		<components.Option {...props}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<img src={`https://flagcdn.com/w20/${props.value.toLowerCase()}.png`} alt={props.value} />{" "}
				<span style={{ marginLeft: "1rem" }}>{props.label}</span>
			</div>
		</components.Option>
	);

	const getCountries = (values: IFormData) => {
		return orderBy(
			countries
				.filter((x) => {
					if (values.taxId != null && values.taxId.trim() !== "") {
						return stripeCountries.includes(x.cca2.toLowerCase());
					} else {
						return true;
					}
				})
				.map((country) => ({
					value: country.cca2,
					label: I18n.locale === "de" ? country.translations["deu"].common : country.name.common
				})),
			(x) => x.label
		);
	};

	return (
		<div className={styles.formContainer}>
			<h3 className={styles.formHeader}>{I18n.t("general.invoiceData")}</h3>
			<div className={styles.formDataContainer}>
				<div className="mb-small">
					<Field name="name" component={FormInput} style={{ width: "100%" }} placeholder={I18n.t("invoiceDataPage.name")} />
				</div>

				<div className={classNames("mb-small", styles.streetRow)}>
					<div className={styles.street}>
						<Field name="street" component={FormInput} placeholder={I18n.t("invoiceDataPage.street")} />
					</div>
					<div className={styles.streetNumber}>
						<Field name="streetNumber" component={FormInput} placeholder={I18n.t("invoiceDataPage.streetNumber")} />
					</div>
				</div>

				<div className={classNames("mb-small", styles.cityRow)}>
					<div className={styles.postalCode}>
						<Field name="postalCode" component={FormInput} placeholder={I18n.t("invoiceDataPage.postalCode")} />
					</div>
					<div className={styles.city}>
						<Field name="city" component={FormInput} placeholder={I18n.t("invoiceDataPage.city")} />
					</div>
				</div>

				<div className="mb-small">
					<Field name="country">
						{(props) => (
							<Select
								name={props.input.name}
								value={props.input.value}
								onChange={props.input.onChange}
								singleValueComponent={SingleValue}
								optionComponent={SingleOption}
								options={getCountries(values)}
								placeholder={I18n.t("invoiceDataPage.country")}
								errorMessage={props.meta.error && props.meta.touched ? props.meta.error : undefined}
							/>
						)}
					</Field>
				</div>

				<div className="mb-small">
					<Field name="taxId" component={FormInput} placeholder={I18n.t("invoiceDataPage.uid")} style={{ width: "100%" }} />
					<OnChange name="taxId">
						{(value) => {
							if (
								value != null &&
								value.trim() !== "" &&
								values.country != null &&
								!stripeCountries.includes(values.country.value.toLowerCase())
							) {
								form.change("country", undefined);
							}
						}}
					</OnChange>
				</div>
			</div>
		</div>
	);
};

export default InvoiceData;
