import React from "react";
import { Field, useFormState } from "react-final-form";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { FormInput } from "../../../common/input";
import { FormCheckbox } from "../../../common/checkbox";
import TotalPrice from "../../../total-price";
import { IBundle } from "../../../../dto/bundle";
import I18n from "../../../../utils/i18n";
import { getDiscountedNetPrice, includesVat } from "../../../../utils/utils";
import { IFormData } from "../../../../dto/form";

interface IMailCheckoutFormProps {
	quantity?: number;
	bundle: IBundle;
}

const MailCheckoutForm = (props: IMailCheckoutFormProps) => {
	const { quantity, bundle } = props;
	const { values } = useFormState<IFormData>();

	return (
		<div className={styles.formContainer}>
			<h3 className={styles.formHeader}>{I18n.t("general.order")}</h3>
			<div className={styles.formDataContainer}>
				<div style={{ marginBottom: "1.2rem" }}>
					<Field name="mail" component={FormInput} style={{ width: "100%" }} placeholder={I18n.t("general.mailAddress")} />
				</div>
				<div style={{ marginBottom: "1.2rem" }}>
					<Field type="checkbox" name="subscribe" component={FormCheckbox} fancyLabel={I18n.t("mailPage.subscribeText")} />
				</div>
				<div>
					<Field
						type="checkbox"
						name="agbAccepted"
						component={FormCheckbox}
						fancyLabel={
							<span>
								<a
									className="link"
									href={`https://www.gethair.me/agb?lang=${I18n.locale}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{I18n.t("mailPage.tosLabel")}
								</a>
								{I18n.t("mailPage.tosText")}
							</span>
						}
					/>
				</div>
			</div>
			<div className={classNames(styles.formDataPadding, "mt-small", "mb-large")}>
				<TotalPrice includesVat={includesVat(values)} totalPrice={getDiscountedNetPrice(bundle, quantity || 1)} />
			</div>
		</div>
	);
};

export default MailCheckoutForm;
