import { maxBy } from "lodash";
import { IFormData } from "../dto/form";

import { IBundle } from "../dto/bundle";

export const includesVat = (values?: IFormData) => {
	return !values || values.taxId == null || values.taxId.trim() === "" || values.country.value === "AT";
};

export const getDiscountedNetPrice = (bundle: IBundle, quantity: number) => {
	const { netPrice, quantityDiscounts } = bundle;

	if (!quantityDiscounts || quantityDiscounts.length === 0) {
		return netPrice * quantity;
	}

	const qualifiedDiscount = maxBy(
		quantityDiscounts.filter((x) => x.quantity <= quantity),
		(x) => x.quantity
	);

	if (qualifiedDiscount) {
		return qualifiedDiscount.netPrice * quantity;
	}

	return netPrice * quantity;
};

export const getLocation = () => {
	return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;
};
