import React, { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { useStripe } from "@stripe/react-stripe-js";

import styles from "./styles.module.scss";

import { useQuery } from "../../utils/use-query";
import GetHairLogo from "../../assets/images/gethair.png";
import { PaymentIntent, StripeError } from "@stripe/stripe-js";
import Success from "../../components/success";
import Error from "../../components/error";
import I18n, { availableLanguages } from "../../utils/i18n";

const Complete = () => {
	const query = useQuery();
	const stripe = useStripe();

	const paymentIntentSecret = query.get("payment_intent_client_secret");
	const language = query.get("lang");

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<StripeError | undefined>();
	const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>();

	useEffect(() => {
		const loadPaymentIntent = async () => {
			setLoading(true);
			if (!stripe || !paymentIntentSecret) {
				return;
			}

			const { paymentIntent, error } = await stripe.retrievePaymentIntent(paymentIntentSecret);
			setPaymentIntent(paymentIntent);
			setError(error);

			setLoading(false);
		};

		loadPaymentIntent();
	}, [paymentIntentSecret, stripe]);

	useEffect(() => {
		if (typeof language === "string" && availableLanguages.includes(language.toLowerCase())) {
			I18n.locale = language;
		}
	}, [language]);

	if (loading || !paymentIntent) {
		return (
			<div className={styles.loadingContainer}>
				<PuffLoader color="white" />
			</div>
		);
	}

	const renderContent = () => {
		if (error || !paymentIntent || (paymentIntent.status !== "succeeded" && paymentIntent.status !== "processing")) {
			return <Error error={error} />;
		} else {
			return <Success />;
		}
	};

	return (
		<>
			<img className={styles.getHairLogo} src={GetHairLogo} alt="GetHair Logo" />

			{renderContent()}
		</>
	);
};

export default Complete;
