import React, { HTMLProps, useState } from "react";
import classNames from "classnames";
import { FieldRenderProps, AnyObject } from "react-final-form";
import {
	StripeCardNumberElementOptions,
	StripeCardNumberElementChangeEvent,
	StripeCardCvcElementChangeEvent,
	StripeCardExpiryElementChangeEvent
} from "@stripe/stripe-js";
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";

import styles from "./styles.module.scss";

interface IInputProps extends HTMLProps<HTMLInputElement> {
	showError?: boolean;
	errorMessage?: string;
}

const Input = (props: IInputProps) => {
	const { className, showError, errorMessage, ...otherProps } = props;
	return (
		<div>
			<input
				{...otherProps}
				className={classNames(styles.inputField, className, { [styles.error]: showError && errorMessage })}
			></input>
			{showError && errorMessage && (
				<div className="error" style={{ marginTop: "5px", marginLeft: "5px" }}>
					{errorMessage}
				</div>
			)}
		</div>
	);
};

interface IStripeCardNumberInputProps {
	options?: StripeCardNumberElementOptions;
	showError?: boolean;
	errorMessage?: string;
	onChange?: ((event: StripeCardNumberElementChangeEvent) => any) | undefined;
}

export const StripeCardNumberInput = (props: IStripeCardNumberInputProps) => {
	const { options, errorMessage, showError, onChange } = props;
	const [focused, setFocused] = useState(false);

	return (
		<div>
			<div className={classNames(styles.inputField, { [styles.focused]: focused, [styles.error]: showError && errorMessage })}>
				<CardNumberElement
					options={options}
					onChange={onChange}
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
				/>
			</div>
			{showError && errorMessage && (
				<div className="error" style={{ marginTop: "5px", marginLeft: "5px" }}>
					{errorMessage}
				</div>
			)}
		</div>
	);
};

interface IStripeCVCInputProps {
	options?: StripeCardNumberElementOptions;
	showError?: boolean;
	errorMessage?: string;
	onChange?: ((event: StripeCardCvcElementChangeEvent) => any) | undefined;
}

export const StripeCVCInput = (props: IStripeCVCInputProps) => {
	const { options, errorMessage, showError, onChange } = props;
	const [focused, setFocused] = useState(false);

	return (
		<div>
			<div className={classNames(styles.inputField, { [styles.focused]: focused, [styles.error]: showError && errorMessage })}>
				<CardCvcElement options={options} onChange={onChange} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} />
			</div>
			{showError && errorMessage && (
				<div className="error" style={{ marginTop: "5px", marginLeft: "5px" }}>
					{errorMessage}
				</div>
			)}
		</div>
	);
};

interface IStripeExpiryInputProps {
	options?: StripeCardNumberElementOptions;
	showError?: boolean;
	errorMessage?: string;
	onChange?: ((event: StripeCardExpiryElementChangeEvent) => any) | undefined;
}

export const StripeExpiryInput = (props: IStripeExpiryInputProps) => {
	const { options, errorMessage, showError, onChange } = props;
	const [focused, setFocused] = useState(false);

	return (
		<div>
			<div className={classNames(styles.inputField, { [styles.focused]: focused, [styles.error]: showError && errorMessage })}>
				<CardExpiryElement
					options={options}
					onChange={onChange}
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
				/>
			</div>
			{showError && errorMessage && (
				<div className="error" style={{ marginTop: "5px", marginLeft: "5px" }}>
					{errorMessage}
				</div>
			)}
		</div>
	);
};

export const FormInput = ({ input, meta, ...otherProps }: FieldRenderProps<any> & AnyObject) => {
	const showError = meta.error && meta.touched;
	return <Input value={input.value} onChange={input.onChange} showError={showError} errorMessage={meta.error} {...otherProps}></Input>;
};

export const FormStripeCardNumberInput = ({ input, meta, ...otherProps }: FieldRenderProps<any> & AnyObject) => {
	const showError = meta.error && meta.touched;
	return (
		<StripeCardNumberInput
			onChange={(e) => input.onChange(e.complete)}
			showError={showError}
			errorMessage={meta.error}
			{...otherProps}
		/>
	);
};

export const FormStripeExpiryInput = ({ input, meta, ...otherProps }: FieldRenderProps<any> & AnyObject) => {
	const showError = meta.error && meta.touched;
	return (
		<StripeExpiryInput onChange={(e) => input.onChange(e.complete)} showError={showError} errorMessage={meta.error} {...otherProps} />
	);
};

export const FormStripeCVCInput = ({ input, meta, ...otherProps }: FieldRenderProps<any> & AnyObject) => {
	const showError = meta.error && meta.touched;
	return <StripeCVCInput onChange={(e) => input.onChange(e.complete)} showError={showError} errorMessage={meta.error} {...otherProps} />;
};

export default Input;
