const se = {
	number: { format: { precision: 2, seperator: ".", delimiter: ",", strip_insignificant_zeros: false } },
	general: {
		order: "Ordning",
		payment: "Betalning",
		mail: "E-post",
		mailAddress: "E-postadress",
		total: "Total",
		withVAT: "inkl 20% moms",
		withoutVAT: "exkl. moms",
		continue: "Fortsätta",
		pay: "Betala",
		error: "Ett fel inträffade. Vänligen försök igen senare.",
		required: "Nödvändig",
		invoiceData: "Fakturauppgifter",
		apply: "Tillämpa",
		cancel: "Avbryt",
		paymentMethod: "Betalningsmetod",
		paymentMethods: {
			creditCard: "Kreditkort",
			sofort: "Sofortüberweisung"
		}
	},
	productInfo: {
		quantity: "Kvantitet:",
		defaultFormOfAddress: "1 användare",
		defaultFormOfAddressPlural: "Från %{kvantitet} användare"
	},
	mailPage: {
		subscribeText: "GETHAIR kan informera mig om nyheter och uppdateringar. Avregistrering är tillgänglig när som helst.",
		tosLabel: "Villkor",
		tosText: " läst och accepterat",
		validation: {
			mailRequired: "E-postadress krävs",
			mailInvalid: "Inte en giltig e-postadress",
			tosRequired: "Villkor måste accepteras"
		}
	},
	paymentPage: {
		cardHolder: "Korthållare",
		accountHolder: "Konto ägare",
		validation: {
			paymentMethodRequired: "Välj en betalningsmetod",
			cardHolderRequired: "Kortinnehavare krävs",
			cardNumberInvalid: "Kortnumret är ogiltigt",
			invalid: "Ogiltig"
		},
		errors: {
			cvcInvalid: "CVC du angav är ogiltigt.",
			cardDeclined: "Ditt kort har nekats.",
			cardExpired: "Ditt kort har gått ut.",
			uidInvalid: "Det skatte-ID du angav är ogiltigt."
		}
	},
	invoiceDataPage: {
		invoiceData: "Fakturauppgifter",
		name: "Ditt namn eller företagsnamn",
		street: "Gata",
		streetNumber: "Nej.",
		postalCode: "Postnummer",
		city: "Stad",
		country: "Land",
		uid: "Skatte ID",
		validation: { uidInvalid: "Ogiltigt skatte-ID" }
	},
	successPage: {
		paymentSuccessful: "Betalning genomförd",
		text: "Vi har skickat all information om din beställning till <strong>%{mail}</strong>.",
		backToProduct: "Tillbaka till produkten",
		yourUnlockCode: "Din upplåsningskod"
	},
	errorPage: {
		paymentFailed: "Betalning misslyckades",
		text1: "Det uppstod ett fel med din betalning. Försök igen senare.",
		text2: "Om felet kvarstår hjälper vi dig gärna!",
		text3: "Om du kontaktar oss, skicka oss detta felmeddelande så att vi bättre kan ta reda på vad som gick fel:",
		restartPayment: "Starta om betalningen"
	}
};

export default se;
