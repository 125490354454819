import React from "react";
import { StripeError } from "@stripe/stripe-js";
import classNames from "classnames";
import { useHistory } from "react-router";
import queryString from "query-string";

import styles from "./styles.module.scss";
import { ReactComponent as FailedIcon } from "../../assets/icons/failed.svg";
import I18n from "../../utils/i18n";
import Button from "../common/button";
import { useQuery } from "../../utils/use-query";

interface IErrorProps {
	error?: StripeError;
}

const Error = (props: IErrorProps) => {
	let { error } = props;
	const query = useQuery();
	const history = useHistory();

	const callbackUrl = query.get("callbackUrl");
	const language = query.get("lang");
	const bundleId = query.get("bundleId");

	const restartPurchase = () => {
		const urlParams = {
			bundleId,
			lang: language
		};

		const targetUrl = queryString.stringifyUrl({
			url: `/`,
			query: urlParams
		});

		history.push(targetUrl);
	};

	return (
		<>
			<div className={classNames("box", styles.container)}>
				<div className={styles.headerContainer}>
					<div className={classNames(styles.titleContainer, "mb-small")}>
						<FailedIcon width={30} /> <h3>{I18n.t("errorPage.paymentFailed")}</h3>
					</div>
					<p className="mb-small">{I18n.t("errorPage.text1")}</p>
					<p> {I18n.t("errorPage.text2")}</p>

					{error && (
						<>
							<p className="mt-small">{I18n.t("errorPage.text3")}</p>
							<div className={styles.codeContainer}>{error.message}</div>
						</>
					)}
					<div className="mt-small">
						<Button onClick={restartPurchase}>{I18n.t("errorPage.restartPayment")}</Button>
					</div>
				</div>
				<div className={styles.supportContainer}>
					<h3 className="mb-micro">Support</h3>
					<p className="mb-micro">support@gethair.me</p>
					<p>0043 699 110 868 87</p>
				</div>
			</div>
			{callbackUrl && (
				<div className={classNames(styles.backToProductButton, "mt-medium")}>
					<Button
						onClick={() => {
							window.location.href = callbackUrl;
						}}
					>
						{I18n.t("successPage.backToProduct")}
					</Button>
				</div>
			)}
		</>
	);
};

export default Error;
