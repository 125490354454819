import React from "react";
import { Field } from "react-final-form";

import { FormInput } from "../../common/input";
import I18n from "../../../utils/i18n";

const SofortForm = () => {
	return (
		<div className="mb-small">
			<Field
				name="cardHolder"
				component={FormInput}
				style={{ width: "100%" }}
				placeholder={I18n.t("paymentPage.accountHolder")}
			></Field>
		</div>
	);
};

export default SofortForm;
