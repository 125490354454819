import I18n from "i18n-js";

import de from "../lang/de";
import en from "../lang/en";
import se from "../lang/se";
import es from "../lang/es";
import pt from "../lang/pt";

import { ILanguageEntry } from "../dto/language-entry";

I18n.defaultLocale = "en";
I18n.fallbacks = true;

I18n.translations = {
	de,
	en,
	se,
	es,
	pt
};

export const availableLanguages = ["de", "en", "se", "es", "pt"];

export const getLanguageEntryText = (languageEntry?: ILanguageEntry) => {
	if (languageEntry == null) {
		return "";
	}

	if (languageEntry.hasOwnProperty(I18n.locale) && languageEntry[I18n.locale] != null) {
		return languageEntry[I18n.locale];
	}

	if (languageEntry.hasOwnProperty("en") && languageEntry["en"] != null) {
		return languageEntry["en"];
	}

	for (let property in languageEntry) {
		if (languageEntry[property] != null) {
			return languageEntry[property];
		}
	}

	return "";
};

export default I18n;
