import React, { HTMLProps } from "react";
import { FieldRenderProps, AnyObject } from "react-final-form";

import styles from "./styles.module.scss";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";

interface ICheckboxProps extends HTMLProps<HTMLInputElement> {
	checked?: boolean;
	fancyLabel?: string | React.ReactNode;
	showError?: boolean;
	errorMessage?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = (props: ICheckboxProps) => {
	const { checked, fancyLabel, showError, errorMessage, onChange } = props;

	return (
		<div>
			<label className={styles.checkbox}>
				<input type="checkbox" checked={checked} onChange={onChange} style={{ visibility: "hidden", display: "none" }} />
				<div className={styles.box}>
					<CheckIcon />
				</div>
				<div className={styles.labels}>
					<span className={styles.label}>{fancyLabel}</span>
					{showError && errorMessage && (
						<div className="error" style={{ marginTop: "5px" }}>
							{errorMessage}
						</div>
					)}
				</div>
			</label>
		</div>
	);
};

export const FormCheckbox = ({ input, meta, ...otherProps }: FieldRenderProps<any> & AnyObject) => {
	const showError = meta.error && meta.touched;
	return (
		<Checkbox
			checked={input.checked}
			onChange={input.onChange}
			showError={showError}
			errorMessage={meta.error}
			{...otherProps}
		></Checkbox>
	);
};

export default Checkbox;
