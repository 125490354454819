import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface IButtonProps {
	children: React.ReactNode;
	submit?: boolean;
	disabled?: boolean;
	loading?: boolean;
	onClick?: () => void;
}

const Button = (props: IButtonProps) => {
	const { children, submit, disabled, loading, onClick } = props;

	return (
		<button
			type={submit ? "submit" : "button"}
			className={classNames(styles.button, { [styles.disabled]: disabled })}
			onClick={onClick}
			disabled={disabled || loading}
		>
			{loading ? <PuffLoader color="white" size={20} /> : children}
		</button>
	);
};

export default Button;
