import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface IStepsProps {
	steps: string[];
	activeStepIndex: number;
}

const Steps = (props: IStepsProps) => {
	const { steps, activeStepIndex } = props;

	return (
		<div className={styles.container}>
			{steps.map((step, index) => (
				<div className={styles.stepContainer} key={step}>
					<div className={classNames(styles.stepNumber, { [styles.active]: index === activeStepIndex })}>{index + 1}</div>
					<label className={styles.stepName}>{step}</label>
				</div>
			))}
		</div>
	);
};

export default Steps;
