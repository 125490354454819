import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PuffLoader } from "react-spinners";
import queryString from "query-string";

import axios from "../../axios";
import { IBundle } from "../../dto/bundle";
import I18n, { availableLanguages } from "../../utils/i18n";
import { useQuery } from "../../utils/use-query";
import GetHairLogo from "../../assets/images/gethair.png";
import styles from "./styles.module.scss";
import ProductInfo from "../../components/product-info";
import CheckoutForm from "../../components/checkout-form";
import { trackPageLoad } from "../../utils/mixpanel";

const Checkout = () => {
	const query = useQuery();
	const history = useHistory();

	const language = query.get("lang");
	const bundleId = query.get("bundleId");
	const mail = query.get("mail");

	const [bundle, setBundle] = useState<IBundle | undefined>(undefined);
	const [stripeCountries, setStripeCountries] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);
	const [quantity, setQuantity] = useState<number | undefined>(1);

	useEffect(() => {
		const loadData = async (bundleId: string) => {
			const result = await axios.get<IBundle>(`/bundle/sales/${bundleId}`);
			const countriesResult = await axios.get<string[]>("/stripe/countries");

			setStripeCountries(countriesResult.data);
			setBundle(result.data);
			setLoading(false);
		};

		if (typeof language === "string" && availableLanguages.includes(language.toLowerCase())) {
			I18n.locale = language;
		}

		if (bundleId && typeof bundleId === "string") {
			loadData(bundleId);
		} else {
			setLoading(false);
		}

		trackPageLoad(bundleId, language);
	}, [language, bundleId]);

	if (loading || !bundle) {
		return (
			<div className={styles.loadingContainer}>
				<PuffLoader color="white" />
			</div>
		);
	}

	return (
		<>
			<img className={styles.getHairLogo} src={GetHairLogo} alt="GetHair Logo" />
			<div className={styles.contentContainer}>
				<div className={styles.productInfoContainer}>
					<ProductInfo quantity={quantity} onQuantityChanged={(quantity) => setQuantity(quantity)} bundle={bundle} />
				</div>
				<div className={styles.checkoutFormContainer}>
					<CheckoutForm
						mail={mail || undefined}
						quantity={quantity}
						bundle={bundle}
						language={language || undefined}
						stripeCountries={stripeCountries}
						onPaymentSuccessful={(paymentData) => {
							const urlParams = {
								payment_intent: paymentData.paymentIntentId,
								payment_intent_client_secret: paymentData.paymentIntentSecret,
								mail: paymentData.mail,
								bundleId: paymentData.bundle.id,
								includesVat: String(paymentData.includesVat),
								quantity: String(paymentData.quantity),
								lang: language
							};

							history.push(
								queryString.stringifyUrl({
									url: "/complete",
									query: urlParams
								})
							);
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default Checkout;
