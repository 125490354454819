import React, { Component } from "react";
import classNames from "classnames";
import { Form } from "react-final-form";

import Steps from "../common/steps";
import Button from "../common/button";
import { ReactComponent as BackButton } from "../../assets/icons/back.svg";
import I18n from "../../utils/i18n";
import { trackStep } from "../../utils/mixpanel";

interface IWizardPageProps {
	children: React.ReactNode;
	validate?: (values: any) => any;
}

interface IWizardProps<T> {
	initialValues?: Partial<T>;
	children: React.ReactNode[];
	showFormButtons?: boolean;
	payButtonDisabled?: boolean;
	onSubmit: (values: T) => void;
}

interface IWizardState<T> {
	page: number;
	values: Partial<T>;
}

class Wizard<TValues> extends Component<IWizardProps<TValues>, IWizardState<TValues>> {
	static Page = ({ children }: IWizardPageProps) => <div>{children}</div>;

	constructor(props: IWizardProps<TValues>) {
		super(props);
		this.state = {
			page: 0,
			values: props.initialValues || {}
		};
	}

	componentDidMount() {
		trackStep(this.state.page);
	}

	next = (values: any) => {
		this.setState(
			(state) => ({
				page: Math.min(state.page + 1, this.props.children.length - 1),
				values
			}),
			() => {
				trackStep(this.state.page);
			}
		);
	};

	previous = () => {
		this.setState(
			(state) => ({
				page: Math.max(state.page - 1, 0)
			}),
			() => {
				trackStep(this.state.page);
			}
		);
	};

	handleSubmit = (values: any) => {
		const { children, onSubmit } = this.props;
		const { page } = this.state;

		const isLastPage = page === React.Children.count(children) - 1;

		if (isLastPage) {
			return onSubmit(values);
		} else {
			this.next(values);
		}
	};

	validate = (values: Partial<TValues>) => {
		const activePage = React.Children.toArray(this.props.children)[this.state.page] as any;
		return activePage.props.validate ? activePage.props.validate(values) : {};
	};

	render() {
		const { children, payButtonDisabled } = this.props;
		const { page, values } = this.state;

		const activePage = React.Children.toArray(children)[page];
		const isLastPage = page === React.Children.count(children) - 1;

		return (
			<Form<TValues> initialValues={values} validate={(values) => this.validate(values)} onSubmit={this.handleSubmit}>
				{({ handleSubmit, submitting }) => {
					return (
						<>
							<form
								onSubmit={handleSubmit}
								className="box"
								style={{ padding: "3rem 0", display: "flex", flexDirection: "column" }}
							>
								{page > 0 && this.props.showFormButtons && (
									<div
										style={{ left: "1rem", top: "1rem", position: "absolute", cursor: "pointer" }}
										onClick={this.previous}
									>
										<BackButton />
									</div>
								)}
								{activePage}
								{this.props.showFormButtons && (
									<div style={{ alignSelf: "center" }} className={classNames("mb-mini")}>
										{isLastPage ? (
											<Button submit loading={submitting} disabled={payButtonDisabled}>
												{I18n.t("general.pay")}
											</Button>
										) : (
											<Button submit>{I18n.t("general.continue")}</Button>
										)}
									</div>
								)}
							</form>
							<div className="mt-small" style={{ padding: "0 2rem" }}>
								<Steps
									steps={[I18n.t("general.mail"), I18n.t("general.invoiceData"), I18n.t("general.pay")]}
									activeStepIndex={page}
								></Steps>
							</div>
						</>
					);
				}}
			</Form>
		);
	}
}

export default Wizard;
