const en = {
	number: {
		format: {
			precision: 2,
			seperator: ".",
			delimiter: ",",
			strip_insignificant_zeros: false
		}
	},
	general: {
		order: "Order",
		payment: "Payment",
		mail: "E-Mail",
		mailAddress: "E-Mail address",
		total: "Total",
		withVAT: "incl 20% VAT",
		withoutVAT: "excl VAT",
		continue: "Continue",
		pay: "Pay",
		error: "An error occured. Please try again later.",
		required: "Required",
		invoiceData: "Invoice data",
		apply: "Apply",
		cancel: "Cancel",
		paymentMethod: "Payment method",
		paymentMethods: {
			creditCard: "Credit card",
			sofort: "Sofortüberweisung"
		}
	},
	productInfo: {
		quantity: "Quantity:",
		defaultFormOfAddress: "1 user",
		defaultFormOfAddressPlural: "From %{quantity} users"
	},
	mailPage: {
		subscribeText: "GETHAIR can inform me about news and updates. Unsubscribing is available at any time.",
		tosLabel: "Terms and Conditions",
		tosText: " read and accepted",
		validation: {
			mailRequired: "E-Mail address is required",
			mailInvalid: "Not a valid e-mail address",
			tosRequired: "Terms and Conditions need to be accepted"
		}
	},
	paymentPage: {
		cardHolder: "Cardholder",
		accountHolder: "Account owner",
		validation: {
			paymentMethodRequired: "Please select a payment method",
			cardHolderRequired: "Cardholder is required",
			cardNumberInvalid: "Card number is invalid",
			invalid: "Invalid"
		},
		errors: {
			cvcInvalid: "The CVC you entered is invalid.",
			cardDeclined: "Your card has been declined.",
			cardExpired: "Your card has expired.",
			uidInvalid: "The tax ID you entered is invalid."
		}
	},
	invoiceDataPage: {
		invoiceData: "Invoice data",
		name: "Your name or company name",
		street: "Street",
		streetNumber: "Nr.",
		postalCode: "Zip code",
		city: "City",
		country: "Country",
		uid: "Tax ID",
		validation: {
			uidInvalid: "Invalid tax ID"
		}
	},
	successPage: {
		paymentSuccessful: "Payment successful",
		text: "We have sent all details of your order to <strong>%{mail}</strong>.",
		backToProduct: "Back to product",
		yourUnlockCode: "Your unlock code"
	},
	errorPage: {
		paymentFailed: "Payment failed",
		text1: "There was an error with your payment, please try again later.",
		text2: "If the error persists, we will be happy to help you!",
		text3: "If you contact us, please send us this error message so that we can better find out what went wrong:",
		restartPayment: "Restart payment"
	}
};

export default en;
