import React from "react";
import Modal from "react-modal";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

interface IErrorModalProps {
	isOpen: boolean;
	errorMessage: string;
	title?: string;
	onClose?: () => void;
}

const ErrorModal = (props: IErrorModalProps) => {
	const { isOpen, errorMessage, title, onClose } = props;

	return (
		<Modal
			isOpen={isOpen}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick={false}
			onRequestClose={onClose}
			style={{
				content: {
					width: 400,
					border: "solid 1px #f8f7f8",
					borderRadius: 12,
					height: "auto",
					top: "50%",
					bottom: "auto",
					left: "50%",
					right: "auto",
					marginRight: "-50%",
					transform: "translate(-50%, -50%)"
				},
				overlay: {
					backgroundColor: "rgba(0, 0, 0, 0.2)"
				}
			}}
		>
			<div className={styles.modalContainer}>
				<div className={styles.closeIconContainer} onClick={onClose}>
					<CloseIcon />
				</div>
				<ErrorIcon width={25} height={25} className="mb-micro" />
				<h2 className={styles.title}>{title || "Error"}</h2>

				<div className={classNames("mt-small", styles.horizontalLine)}></div>

				<p className="mt-small mb-medium">{errorMessage}</p>
			</div>
		</Modal>
	);
};

export default ErrorModal;
