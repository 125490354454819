import React from "react";

import styles from "./styles.module.scss";
import I18n from "../../utils/i18n";

interface ITotalPriceProps {
	includesVat: boolean;
	totalPrice: number;
}

const TotalPrice = (props: ITotalPriceProps) => {
	const { includesVat, totalPrice } = props;

	return (
		<div className={styles.container}>
			<div>
				<label>{I18n.t("general.total")}</label>
				{includesVat === true && <label className={styles.vatLabel}>{I18n.t("general.withVAT")}</label>}
			</div>
			<div className={styles.price}>EUR {I18n.toNumber(includesVat ? totalPrice * 1.2 : totalPrice)}</div>
		</div>
	);
};

export default TotalPrice;
