const de = {
	number: {
		format: {
			precision: 2,
			separator: ",",
			delimiter: ".",
			strip_insignificant_zeros: false
		}
	},
	general: {
		order: "Bestellung",
		payment: "Bezahlen",
		mail: "E-Mail",
		mailAddress: "E-Mail Adresse",
		total: "Gesamt",
		withVAT: "inkl. 20% USt.",
		withoutVAT: "exkl. USt.",
		continue: "Fortfahren",
		pay: "Bezahlen",
		error: "Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
		required: "Verpflichtend",
		invoiceData: "Rechnungsdaten",
		apply: "Anwenden",
		cancel: "Abbrechen",
		paymentMethod: "Zahlungsart",
		paymentMethods: {
			creditCard: "Kreditkarte",
			sofort: "Sofort Überweisung"
		}
	},
	productInfo: {
		quantity: "Anzahl:",
		defaultFormOfAddress: "1 User",
		defaultFormOfAddressPlural: "Ab %{quantity} User"
	},
	mailPage: {
		subscribeText: "GETHAIR darf mich über Neuigkeiten informieren. Eine Abmeldung ist jederzeit möglich.",
		tosLabel: "AGBs",
		tosText: " gelesen und akzeptiert",
		validation: {
			mailRequired: "E-Mail Adresse ist verpflichtend",
			mailInvalid: "Keine gültige E-Mail Adresse",
			tosRequired: "AGBS müssen akzeptiert werden"
		}
	},
	paymentPage: {
		cardHolder: "Karteninhaber",
		accountHolder: "Kontoinhaber",
		validation: {
			paymentMethodRequired: "Bitte eine Zahlungsmethode auswählen",
			cardHolderRequired: "Karteninhaber ist verpflichtend",
			cardNumberInvalid: "Kartennummer ist ungültig",
			invalid: "Ungültig"
		},
		errors: {
			cvcInvalid: "Der von dir eingebene Sicherheitscode (CVC) stimmt nicht.",
			cardDeclined: "Deine Karte wurde abgelehnt.",
			cardExpired: "Die von dir angegebene Karte ist abgelaufen.",
			uidInvalid: "Die von dir eingegebene UID-Nummer ist ungültig."
		}
	},
	invoiceDataPage: {
		invoiceData: "Rechnungsdaten",
		name: "Dein Name oder Firmenname",
		street: "Straße",
		streetNumber: "Nr.",
		postalCode: "PLZ",
		city: "Stadt",
		country: "Land",
		uid: "UID",
		validation: {
			uidInvalid: "Ungültige UID"
		}
	},
	successPage: {
		paymentSuccessful: "Zahlung erfolgreich",
		text: "Wir haben dir die Details deiner Bestellung per E-Mail an <strong>%{mail}</strong> versendet.",
		backToProduct: "Zurück zum Produkt",
		yourUnlockCode: "Dein Freischaltcode"
	},
	errorPage: {
		paymentFailed: "Zahlung fehlgeschlagen",
		text1: "Es ist ein Fehler bei deiner Zahlung aufgetreten, bitte versuche es zu einem späteren Zeitpunkt erneut.",
		text2: "Falls der Fehler weiterhin auftritt helfen wir dir gerne weiter!",
		text3: "Falls du uns kontaktierst, schicke uns bitte diese Fehlermeldung, damit wir besser herausfinden können was schief gelaufen ist:",
		restartPayment: "Bezahlvorgang neu starten"
	}
};

export default de;
