const es = {
	number: { format: { precision: 2, seperator: ".", delimiter: ",", strip_insignificant_zeros: false } },
	general: {
		order: "Orden",
		payment: "Pago",
		mail: "Correo electrónico",
		mailAddress: "Dirección de correo electrónico",
		total: "Total",
		withVAT: "incl 20% IVA",
		withoutVAT: "excl. IVA",
		continue: "Continuar",
		pay: "Pagar",
		error: "Ocurrió un error. Por favor, inténtelo de nuevo más tarde.",
		required: "Requerido",
		invoiceData: "Datos de facturación",
		apply: "Aplicar",
		cancel: "Cancelar",
		paymentMethod: "Método de pago",
		paymentMethods: {
			creditCard: "Tarjeta de crédito",
			sofort: "Sofortüberweisung"
		}
	},
	productInfo: {
		quantity: "Cantidad:",
		defaultFormOfAddress: "1 usuario",
		defaultFormOfAddressPlural: "De %{cantidad} usuarios"
	},
	mailPage: {
		subscribeText:
			"GETHAIR puede informarme sobre novedades y actualizaciones. La cancelación de la suscripción está disponible en cualquier momento.",
		tosLabel: "Términos y condiciones",
		tosText: " leído y aceptado",
		validation: {
			mailRequired: "Se requiere Dirección de correo electrónico",
			mailInvalid: "La dirección de email no es válida",
			tosRequired: "Deben aceptarse los términos y condiciones"
		}
	},
	paymentPage: {
		cardHolder: "Titular de la tarjeta",
		accountHolder: "Propietario de la cuenta",
		validation: {
			paymentMethodRequired: "Por favor seleccione un método de pago",
			cardHolderRequired: "Se requiere el titular de la tarjeta",
			cardNumberInvalid: "El número de tarjeta no es válido",
			invalid: "Inválido"
		},
		errors: {
			cvcInvalid: "El CVC que ingresó no es válido.",
			cardDeclined: "Su tarjeta ha sido rechazada.",
			cardExpired: "Tu tarjeta ha caducado.",
			uidInvalid: "El número de identificación fiscal que ingresó no es válido."
		}
	},
	invoiceDataPage: {
		invoiceData: "Datos de facturación",
		name: "Tu nombre o razón social",
		street: "calle",
		streetNumber: "No.",
		postalCode: "Código postal",
		city: "Ciudad",
		country: "País",
		uid: "Identificación del impuesto",
		validation: { uidInvalid: "Número de identificación fiscal no válido" }
	},
	successPage: {
		paymentSuccessful: "Pago exitoso",
		text: "Hemos enviado todos los detalles de su pedido a <strong>%{mail}</strong>.",
		backToProduct: "Volver al producto",
		yourUnlockCode: "Tu código de desbloqueo"
	},
	errorPage: {
		paymentFailed: "Pago fallido",
		text1: "Hubo un error con su pago. Vuelva a intentarlo más tarde.",
		text2: "Si el error persiste, ¡estaremos encantados de ayudarte!",
		text3: "Si se comunica con nosotros, envíenos este mensaje de error para que podamos averiguar mejor qué salió mal:",
		restartPayment: "Reiniciar pago"
	}
};

export default es;
